import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

import LoginPic from '../assets/images/login-picture.jpg';
import LoginWrong from '../components/LoginWrong';
import Login from '../components/Login';
import Footer from '../components/Footer';

const WrongCredentials = () => {
  return (
    <>
      <section className="section">
        <div className="section-wrapper_outer">
          <div className="section-img">
            <img src={LoginPic} alt="" />
            <h1>Your bridge to better</h1>
          </div>
          <div className="section-wrapper_inner">
            <div className="section-container">
              <div className="section-container-inner">
                <LoginWrong />
              </div>
              <div className="section-container-inner">
                <Login />
              </div>
              <div className="section-container-inner">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WrongCredentials;
